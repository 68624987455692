<template>
  <div
      class="w-100 d-flex flex-row-reverse"
      style="position: absolute; top: 0; left: 0; z-index: 9999; height: 100%"
    >
      <loading v-if="loading" style="height: 100%; width: 100%; position: absolute; background-color: #555555" class="w-100 h-100" />
      <div v-if="!loading" class="preview-sidebar">
        <div>
          <div class="header">
            <span class="text"> Quick View </span>
            <div
              @click="closePdfView"
              class="close-page-icon cursor-pointer me-0"
            >
              <ph-x weight="bold" :size="22" />
            </div>
          </div>
          <div class="pdf-border"></div>
          <template v-if="$route.name === 'buyerCatalogDetail'">
            <div v-for="(item, index) in filteredItems" :key="index">
              <div class="selectedDocument">
                <div class="icon">
                  <ph-file :size="22" weight="duotone" />
                </div>
                <span>{{ item.stepName }}</span>
              </div>
              <div class="buttons d-flex justify-content-between">
                  <button @click="downloadCatalog(item, index)"  :class="{'disable, pointer-none': getLoading(index)}" class="btn btn-primary px-2 w-100 me-2">
                    <ph-arrow-line-down :size="14" /> {{getLoading(index) ? 'Loading..' : 'Download'}}
                  </button>
                  <button  @click="getDocument(item)" class="btn btn-secondary px-2 w-100 ms-2">
                    <ph-arrow-line-up-right :size="14" /> Quick View
                  </button>
                </div>
            </div>
          </template>

          <div v-if="$route.name === 'sellerCatalogDetail'">
            <template v-for="(item, index) in filteredItems">
              <div :key="index">
                <div class="selectedDocument">
                  <div class="icon">
                    <ph-file :size="22" weight="duotone" />
                  </div>
                  <span>{{ item.stepName }}</span>
                </div>
                <div class="buttons d-flex justify-content-between">
                  <button @click="downloadCatalog(item, index)"  :class="{'disable, pointer-none': getLoading(index)}" class="btn btn-primary px-2 w-100 me-2">
                    <ph-arrow-line-down :size="14" /> {{getLoading(index) ? 'Loading..' : 'Download'}}
                  </button>
                  <button  @click="getDocument(item)" class="btn btn-secondary px-2 w-100 ms-2">
                    <ph-arrow-line-up-right :size="14" /> Quick View
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="pdf-bottom">
          <div class="item">
            <span class="title bg-gray">Status</span>
            <span class="status">{{ selectedItem.status }}</span>
          </div>
          <div class="item">
            <span class="title bg-gray">Gültig</span>
            <span class="gultig">{{ selectedItem.validTill }}</span>
          </div>
        </div>
      </div>
      <div v-if="!loading" class="right flex-1 h-100 pdf-view-container">
        <div class="pdf-view-header">
          <div class="pdf-view-header-left">
            <div :id="idConfig.sidebarToggle" class="icon">
              <ph-copy-simple weight="bold" :size="23" />
            </div>
            <h3 class="title">{{ selectedTitle }}</h3>
          </div>
          <div class="pdf-view-header-center">
            <div class="pages">
              <input
                :id="idConfig.pageNumber"
                type="number"
                name="pageNumber"
                style="width: 40px"
              />
              <span class="divider"> / </span>
              <span class="totalPages">{{ totalPages }}</span>
            </div>
            <div class="divider-items"></div>
            <div class="zoom">
              <button :id="idConfig.zoomOut" class="icon">
                <ph-minus @click="minusPageScale" />
              </button>
              <input
                type="text"
                name="pageNumber"
                readonly="readdonly"
                v-model="getZoomPercent"
              />
              <button :id="idConfig.zoomIn" class="icon">
                <ph-plus @click="plusPageScale" />
              </button>
            </div>
            <div class="divider-items"></div>
            <div class="sort">
              <button :id="idConfig.scrollVertical" class="icon">
                <ph-rows :size="22" />
              </button>
              <button :id="idConfig.scrollHorizontal" class="icon">
                <ph-columns :size="22" />
              </button>
              <button :id="idConfig.pageRotateCcw" class="icon">
                <ph-arrow-arc-left :size="22" />
              </button>
            </div>
          </div>
          <div class="pdf-view-header-right d-flex">
            <button
              class="pdf-search"
              ref="nextFindBtn"
              :id="idConfig.findNext"
            >
              Next
            </button>
            <div class="form-group position-relative">
              <div class="form-control-icon white cursor-pointer">
                <ph-magnifying-glass weight="bold" :size="14" />
              </div>
              <input
                class="form-control transparent"
                placeholder="Search in PDF"
                @keyup.enter="nextFind"
                type="text"
                :id="idConfig.findInput"
              />
            </div>
            <button @click="downloadBySelected" class="icon">
              <ph-download-simple :size="22" />
            </button>
            <button :id="idConfig.print" class="icon">
              <ph-printer :size="22" />
            </button>
          </div>
        </div>
        <vue-pdf-app
          :config="{ toolbar: false }"
          :page-scale="pageScale"
          :id-config="idConfig"
          :pdf="selectedItem.body"
          @open="openHandler"
        ></vue-pdf-app>
      </div>
    </div>
</template>

<script>
import {
  PhMagnifyingGlass,
  PhX,
  PhCopySimple,
  PhMinus,
  PhPlus,
  PhRows,
  PhFile,
  PhColumns,
  PhArrowArcLeft, PhArrowLineUpRight,
  PhArrowLineDown,
  PhDownloadSimple,
  PhPrinter
} from 'phosphor-vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import loading from '@/components/common/Loading.vue'
import { PDFDocument } from 'pdf-lib'
var fileDownload = require('js-file-download')
export default {
  components: {
    PhCopySimple,
    PhFile,
    loading,
    PhArrowLineUpRight,
    PhArrowLineDown,
    PhColumns,
    PhDownloadSimple,
    PhPrinter,
    PhArrowArcLeft,
    PhRows,
    PhPlus,
    PhMagnifyingGlass,
    PhX,
    PhMinus
  },
  props: ['item', 'allItems', 'title'],
  data () {
    return {
      totalPages: 0,
      loading: false,
      selectedTitle: null,
      items: null,
      selectedItem: {
        body: ''
      },
      pageScale: 90,
      loadingBtns: ['1'],
      idConfig: {
        zoomIn: 'vuePdfAppZoomIn',
        zoomOut: 'vuePdfAppZoomOut',
        print: 'vuePdfAppPrint',
        findInput: 'findInputId',
        findNext: 'findNextId',
        sidebarToggle: 'vuePdfAppSidebarToggle',
        pageNumber: 'vuePdfAppPageNumber',
        numPages: 'vuePdfAppNumPages',
        scrollVertical: 'vuePdfAppScrollVertical',
        scrollHorizontal: 'vuePdfAppScrollHorizontal',
        pageRotateCcw: 'vuePdfAppPageRotateCcw'
      }
    }
  },
  computed: {
    ...mapGetters(['getUserRole', 'getAccessToken']),
    filteredItems () {
      return this.items.filter(item => item.stepName !== this.selectedItem.stepName)
    },
    getZoomPercent () {
      return `${this.pageScale}%`
    }
  },
  methods: {
    readFile (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)

        reader.readAsArrayBuffer(file)
      })
    },
    async getNumPages (file) {
      const arrayBuffer = await this.readFile(file)

      const pdf = await PDFDocument.load(arrayBuffer)

      return pdf.getPages()
    },
    getLoading (id) {
      const findIndex = this.loadingBtns.findIndex(item => item === id)
      if (findIndex !== -1) {
        return true
      }
      return false
    },
    deleteLoading (id) {
      for (var i = 0; i < this.loadingBtns.length; i++) {
        if (this.loadingBtns[i] === id) {
          this.loadingBtns.splice(i, 1)
        }
      }
    },
    async downloadCatalog (par, index) {
      try {
        this.loadingBtns.push(index)
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/catalogue/supplier/${par.supplierId}/model/${par.modelId}/download`,
          {
            step: par.stepValue,
            status: par.status,
            validFrom: par.validFrom,
            validTill: par.validTill,
            tz: par.tz

          },
          {
            responseType: 'arraybuffer',
            headers: {
              Authorization: 'Bearer ' + this.getAccessToken
            }
          }
        )
        fileDownload(response.data, `${this.$parent.$parent.$parent.$data.detail.summary.modelName}_${par.tz ? 'TZ' : ''}_${par.validFrom}_${par.validTill}_${par.status}_${par.supplierId}_${par.modelId}_${par.stepName}.pdf`)
      } catch (e) {
        console.error(e)
      } finally {
        this.deleteLoading(index)
      }
    },
    async quickViewCatalog (par) {
      try {
        this.loading = true
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/catalogue/supplier/${par.supplierId}/model/${par.modelId}/download`,
          {
            step: par.stepValue,
            status: par.status,
            validFrom: par.validFrom,
            validTill: par.validTill,
            tz: par.tz

          },
          {
            responseType: 'arraybuffer',
            headers: {
              Authorization: 'Bearer ' + this.getAccessToken
            }
          }
        )
        this.selectedTitle = `${this.$parent.$parent.$parent.$data.detail.summary.modelName}_${par.tz ? 'TZ' : ''}_${par.validFrom}_${par.validTill}_${par.status}_${par.supplierId}_${par.modelId}_${par.stepName}.pdf`
        this.selectedItem = {
          ...par,
          body: response.data
        }
        // fileDownload(response.data, `${this.$parent.$parent.$parent.$data.detail.summary.modelName}_${par.tz ? 'TZ' : ''}_${par.validFrom}_${par.validTill}_${par.status}_${par.supplierId}_${par.modelId}_${par.stepName}.pdf`)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    downloadBySelected () {
      fileDownload(this.selectedItem.body, this.selectedTitle)
    },
    minusPageScale () {
      this.pageScale = this.pageScale - 10
    },
    plusPageScale (par) {
      if (this.pageScale === 310) {
        return
      }
      this.pageScale = this.pageScale + 10
    },
    closePdfView () {
      this.$emit('closed')
    },
    openHandler (pdfApp) {
      window._pdfApp = pdfApp
    },
    nextFind () {
      this.$refs.nextFindBtn.click()
    },
    async getDocument (item) {
      const modelName = this.$parent.$parent.$parent.detail.summary.modelName
      this.selectedTitle = `${
        modelName
      }_${item.tz ? 'TZ' : ''}_${item.validFrom}_${item.validTill}_${
        item.status
      }_${item.supplierId}_${item.modelId}_${item.stepName}.pdf`
      try {
        this.selectedItem = item
        this.loading = true
        const response = await this.$api.viewDocument(item)
        this.selectedItem = {
          ...this.selectedItem,
          body: response.data
        }
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const num = await this.getNumPages(blob)
        this.totalPages = num.length
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    this.selectedTitle = this.title
    this.items = this.allItems
    this.selectedItem = this.item
    this.getDocument(this.selectedItem)
    window.scrollTo(0, 0)
  }
}
</script>
<style>
.preview-sidebar {
  background-color: #555555;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320;
  min-width: 320px
}

@media only screen and (max-width: 1024px) {
  .header {
    padding: 10px 15px!important;
  }
  .close-page-icon {
    margin-left: 0 !important;
  }
  .pdf-bottom {
    padding: 10px 15px !important;
  }
  .preview-sidebar {
    width: 180;
    min-width: 180px
  }
  #viewerContainer{
    margin-left: 325px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .pdf-view-container {
    width: 50%;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1560px) {
  .pdf-view-container {
    width: 70%;
  }
}
</style>
