<template>
  <div class="page-header d-flex justify-content-between align-items-center position-fixed">
    <div class="d-flex align-items-center">
      <span class="back-holder" @click="goBack">
        <ph-arrow-left weight="bold" :size="28" />
      </span>
      <p class="text-color-gray page-title text-uppercase mb-0">
        <span>{{getBreadCrumb[0].title}}</span>
        <span class="text-color-primary font-normal">{{getBreadCrumb[1].title}}</span>
      </p>
    </div>
    <div v-if="!hiddenSearch" class="search-bar">
      <div class="form-group position-relative">
        <div class="form-control-icon">
          <ph-magnifying-glass weight="bold" :size="22" />
        </div>
        <input @keyup.enter="search" type="text" v-model="searchKeyw" class="form-control" placeholder="Lieferant, Modell, Warengruppe" />
      </div>
    </div>
  </div>
</template>

<script>
import { PhMagnifyingGlass, PhArrowLeft } from 'phosphor-vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    PhMagnifyingGlass,
    PhArrowLeft
  },
  props: {
    hiddenSearch: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      searchKeyw: null
    }
  },
  computed: {
    ...mapGetters(['getBreadCrumb'])
  },
  methods: {
    ...mapMutations(['SET_VERKAUF']),
    search () {
      if (this.searchKeyw.length > 2) {
        const einkaufRoutes = ['einkauf', 'buyerModels', 'buyerCatalogDetail']
        this.SET_VERKAUF(!einkaufRoutes.includes(this.$route.name))
        this.$router
          .push({
            path: '/search',
            query: { supplier: this.searchKeyw, global: true, c: this.getCount }
          })
      }
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
